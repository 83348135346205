import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/usr/src/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "cicd-at-hybrit"
    }}>{`CI/CD at HybrIT`}</h1>
    <p>{`For CI/CD we use Azure devops, and we define our build pipelines in the `}<inlineCode parentName="p">{`azure-pipelines.yaml`}</inlineCode>{` in a code project.`}</p>
    <p>{`To unify steps and procedure across projects a template repository is created for use in all pipelines.`}</p>
    <p>{`For the repository containing the templates, follow this `}<a parentName="p" {...{
        "href": "https://github.com/HybrIT-dev/hybrit-azuredevops-templates"
      }}>{`link`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      